import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, AppService } from '@lc/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'lc-app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  standalone: false,
})
export class LogoutComponent implements OnInit {
  @Input() title: string;
  readonly appName: string = AppService.get('applicationName');

  isForcedLogout: boolean = false;
  isLoggedOut$: Observable<boolean>;

  constructor(
    public authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.isLoggedOut$ = this.activatedRoute.queryParamMap.pipe(
      tap((params) => this.isForcedLogout = !!params.get('forcedLogout')),
      switchMap(async () => await this.authService.logout()),
      map(() => true),
    );
  }

  ngOnInit() {
    this.titleService.setTitle(`${this.titleService.getTitle()} - Logout`);
  }

  login() {
    this.authService.redirectToLogin();
  }
}
